.nav{
    width:100%;
    max-width: 100vw;
    height:60px;
  box-shadow: 1px 1px 10px 0px rgb(231, 227, 235);

  padding:10px;
  background:white;
  border-radius: 10px;
}
.dashboardBoxs{
  height:160px;
  max-width:100%;
  width:max-content;
  /* margin:0 auto; */
  border-radius: 10px;
  padding:10px;
  gap:13px;
}

.dashboardBoxs .box{
  width:300px;
  height: 100%;
  border-radius: 10px;
  background:white;
  padding:10px;
}
.dashboardBoxs .box span{
  color:rgb(112, 109, 109);
}
.dashboardBoxs .box h4{
  font-size:18px;
}
.dashboardLists{
  width:98%;
  min-height:52vh;
  border-radius: 10px;
  background: white;
  padding:10px;
}

.taskListCardContainer{
  width:100%;
  min-height: 45vh;
  background:var(--lightgrey);
  border-radius:10px;
padding: 10px;
}
.taskListCardContainer
  .taskListCard{
width:100%;
min-height: 10px;
margin-top:5px;
padding:8px;
background: white;
border-radius: 10px;
  }