.myContainer .card{
    box-shadow:1px 1px 10px 0px var(--lightgrey);
    border-radius: 10px;
    width:150px;
    padding:18px;

}
.myContainer .card:hover{
    box-shadow:inset 1px 1px 10px 0px var(--lightgrey);
    border:1px solid var(--lightgrey);
    cursor:pointer;
    
}
.myContainer .card::selection{
    background: none;
}
.myContainer .card:active{
    border:1px solid var(--orrange)
}

.form{
    width:100%;
}
.form .top{
    width: 100%;
    padding-bottom:20px;
    background:var(--gradient);
    padding:10px;
    border-radius: 10px 10px 0px 0px !important;
}

.form .top *{
    color:rgb(250, 245, 245) !important;
}
.userCard{
    width:100%;
    min-height:20px;
    border-radius: 10px;
    padding:10px;
    background:white;
    box-shadow:1px 1px 10px 0px lightgrey;
    margin-top:10px;

}

.userCard:hover{
    border:1px solid var(--orrange);
    cursor:pointer;
}

.sc-aXZVg{
    border-color:var(--orrange);
}