body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
:root{
  --lightgrey:rgb(245, 245, 247);
  --green:#58A23C;
  --orrange:#F25720;
  --blue:#1279C4;
  --greyBlue:rgb(250, 249, 255);
  --gradient:linear-gradient(30deg,#6d1cc9,#a400f7);
}

.logoAndText{
  background:linear-gradient()
}

.body{
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  gap:10px;
  background:var(--lightgrey);

}
.body > div{
  min-height:100vh;
}
.body .bodyMain{
  flex-grow: 1;
}

@media (min-width:1024px) {


}

@media (max-width:700px) {
.body .sideNav{
 display:none; 
}

.offcanvas aside{
  width:100% !important;
  max-width: 70vw !important;
}
.offcanvas aside li{
  padding-left:0px !important;
transform: translate(-15px,0px);
  }
.offcanvas aside{
  border:none !important;
}

}



@media (max-width:700px) {
  /* mobile settings */
.offcanvas:not(.notificationBackDrop){
  max-width: 70vw !important;
} 
}

@media (max-width:600px) {
  *.m-none{
      display: none !important;
  }
}

@media (max-width:700px) {
  *.m-block{
      display: block!important;
      
  }
  *.m-block > * {
      width:100%;
      min-width:100%;
  }
  *.m-fullWidth-noFlex{
  width:100% !important;
  height: auto;
  display:block !important;
  }
  *.m-fullWidth-noFlex > *{
width:100% !important;
  }
}


@media (max-width:950px) {
*.md-fullWidth-noFlex > *{
  width:100% !important;
    }
  }

  .myContainer{
    max-width: 100%;
    width:max-content;
    min-height: 100px;
    border-radius: 10px;;
    padding:16px;
    background: white;
    box-shadow:1px 1px 10px 0px rgb(251, 251, 252);
  }

  .noEffect::after{
    content: none !important;
    }
    
    .noEffect::before{
      content: none !important;
      }
    
     
.sc-aXZVg{
  border-color:var(--orrange) !important;
}