.Quotes{
    width:300px;
}

.Quotes img{
    width:150px;
    height:150px;
    border-radius: 10px;
    display:block;
    margin:0 auto;
}

.Quotes .quotesText{
    border-radius:10px;
    background: var(--greyBlue);
    padding: 16px;
    max-width:100%;
    word-wrap:wrap;
    word-break:break-word;
    
}