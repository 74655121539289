.chatContainer{
    width:100%;
    padding:2px;
    padding-top:10px;
    
}
.chatContainer .chatBody{
    background:rgb(250, 247, 250);
    min-height: 83vh;
    max-width: 100vw !important;

}

.chatContainer .chatNav{
    min-height:20px;
    padding:10px;
    width:100%;
    background:white;
    box-shadow: 1px 1px 10px 0px rgb(240, 238, 238);
    border-radius:6px;
    max-width: 100vw;
}

@media (max-width:700px){
.chatContainer .chatSide{
display: none;
}

.chatContainer .chatBody{
width:100%;
padding-top:70px !important;
}
.chatContainer .chatNav{
position:fixed;
top:0;
left:0;
z-index:99;
background:white;
}

}
.sideUsers{
    background:white;
    min-height: 90vh;
    width:100%;
    border-radius: 6px;
    padding:10px;
    box-shadow: 1px 1px 10px 0px rgb(240, 238, 238);

}

.chatInput{
    width:100%;
    box-shadow: 1px 1px 10px 0px rgb(240, 238, 238);
    padding:16px;
    padding-top: 2px;
    padding-bottom:2px;
    background:white;
    border-radius: 4px;
    
}
@media (max-width:900px) {
    .chatInput{
        position: fixed;
        width:100%;
        bottom:0;
    }
.chatContainer .chatBody{
padding-bottom: 60px !important;
}

}
.chatInput{

}

.chatInput input{
    width:80%;
    border:none;
    outline:none;
}

.chatBody .message{
    padding:10px;
    background:10px;
    border-radius: 16px;
    border-top-right-radius: 0px;
    width:max-content;
    max-width: 60vw;
    background:var(--blue);
}
@media (min-width:900px) {

.chatBody .message{
max-width: 60% !important;    

}

}
.chatBody .message *,.chatBody .message{
    color:white;
}
.chatBody .message{
    padding:10px;
    background:10px;
    border-radius: 16px;
    border-top-right-radius: 0px;
    width:max-content;
    background:var(--blue);
}
.chatBody .personMessage .message,.chatBody .personMessage .message.black {
color:rgb(39, 39, 43);
}

.chatBody .personMessage .message{
    border-top-right-radius: 16px;
    border-top-left-radius: 0px;
    background:white;
}
.chatBody .file{
    padding:3px;
    background:10px;
    border-radius: 5px;
    border-top-right-radius: 16px;
    border-top-left-radius: 0px;
    width:max-content;

}

.roomSelector{
    margin:0 auto;
    background:white;
    min-width:400px;
    /* height:70%; */
    width:95%;
    border-radius: 10px;
    padding:16px !important;
}
.roomSelector .room{
    border:1px solid rgb(241, 236, 236);
    padding:10px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 0px rgb(235, 232, 232);
}
.roomSelector .room:hover{
    box-shadow: inset 1px 1px 10px 0px rgb(235, 232, 232);
    cursor:pointer;

}
.roomSelector .room:active{
    border:2px solid rgb(214, 213, 221);
    box-shadow:1px 1px 10px 0px rgb(235, 232, 232);

}